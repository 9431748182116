import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook5/dataPageEbook5"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        {" "}
        20 KPIs de Call Center que debes de conocer este 2022
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/kpi-call-center-ebook/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te contamos 20 KPIs de Call Center que sí o sí deben ser consideradas en cualquier de tus campañas, ya sea inbound u outbound. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="20 KPIs de Call Center que debes de conocer este 2022"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/kpi-call-center-ebook/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Te contamos 20 KPIs de Call Center que sí o sí deben ser consideradas en cualquier de tus campañas, ya sea inbound u outbound. "
      />
    </Helmet>
    <TemplatePageEbook ebook="Contact Center" location={location} typeEbook="ebook5" clickHereHref="https://beexcc.com/blog/noticias-contact-center/" data={data} />
  </div>
)

export default IndexPage
